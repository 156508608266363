
import { defineComponent, PropType, computed, reactive, ref, watch } from "vue";
import { message } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import { DataProps } from "../ApprovalChain/ProgramChain/type";
import {Moment} from "moment";
type TypeProp = "new" | "copy";
export default defineComponent({
  name: "chainVersionModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  setup(props,ctx) {
    const _visible = useModel(props, "visible");
    const title = ref<string>('Add New Version')
    const formState = reactive({
      effective: null as Moment | null
    });
    const rules = reactive({
      effective: [
        {
          required: true,
          type: "date",
          message: "Please select effective from",
          trigger: "change",
        },
      ],
    });
    const handleCancel = () => {
      _visible.value = false;
    };
    const formRef = ref();
    const handleConfirm = () => {
      formRef.value
          .validate()
          .then(() => {
            ctx.emit('handle-confirm',formState.effective?.format("YYYY/MM/DD"))
            _visible.value = false;
          })
          .catch((error: any) => {
            console.log("error", error);
          });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    // 关闭弹窗清空表单
    watch(_visible, (val) => {
      if (!val) {
        resetForm();
      }
    });

    // 切换copy
    const getById = (arr: DataProps[], id: string) => {
      return arr.filter((item) => item.id === id)[0];
    };

    return {
      _visible,
      title,
      formState,
      rules,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      formRef,
      handleCancel,
      handleConfirm
    };
  },
});
