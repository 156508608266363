
import { defineComponent, ref, reactive, watch, computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import ChainVersionModal from "@/views/User/ApprovalProcess/components/chainVersionModal.vue";
import useProgramChian from "@/hooks/approvalChian/useProgramChian";
import { FormOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import * as API from "@/API/approvalChain"
import deepClone from '@/utils/deepClone'
import { message } from "ant-design-vue"
import moment from 'moment'

interface DataItem {
  key: string;
  approvalLevel: string;
  approvalRole: number;
  user: string;
}
interface TabItem {
  index: string;
  name: string;
  query?: Record<string, string>;
}
export default  defineComponent({
  name: "edit",
  props: {},
  components:{
    ChainVersionModal,
    PlusCircleOutlined,
    DeleteOutlined
  },
  setup() {
    const buName = ref<string>('')
    const makeName = ref('')
    const chainName = ref<string>('')
    const approvalBusinessId = ref<string>('')
    const tabDate = ref<string>('')

    const formatDate = (date: string) => {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('/');
    }
    const route = useRoute();

    buName.value = route.query.bu as string
    makeName.value = route.query.make as string
    chainName.value = route.query.chainName as string

    interface EffectiveDate {
      currentEffectiveFlag: true | null;
      effectiveDate: string;
      effectiveFormatDate: string;
      chainId: string;
    }

    const effectiveDates = ref<EffectiveDate[]>([])
    const effectiveDateTab = ref<string>()
    const approvalChainInfo = reactive<Record<string, ApprovalChainInfoSettingData>>({})

    API.getEffectiveDate(route.query.businessId as string).then((res: unknown) => {
      effectiveDates.value = res as EffectiveDate[]
      (res as EffectiveDate[]).forEach((efectiveDate: EffectiveDate) => {
        if (efectiveDate.currentEffectiveFlag) {
          effectiveDateTab.value = efectiveDate.chainId
        }
        approvalChainInfo[efectiveDate.chainId] = {
          data: [],
          inited: false,
          editting: false
        }
        efectiveDate.effectiveFormatDate = moment(efectiveDate.effectiveDate).format("MM/DD/YYYY")
      })
      if (effectiveDateTab.value && route.query.mode === 'edit') {
        approvalChainInfo[effectiveDateTab.value].editting = true
      }
    })

    const allUserOptions = ref()

    const approvalChainInfoDefaultData = new Array(15).fill({
      approvalList: [{
        approvalChainRoleName: '',
        approvalChainRoleId: undefined,
        roleOptions: [],
        approvalChainUserName: '',
        approvalChainUserId: undefined,
        userOptions: []
      }]
    })

    interface ApprovalChainInfoSettingData {
      data: unknown[]; // 数据
      inited: boolean; // 数据是否已加载
      editting: boolean; // 是否正在编辑
    }

    watch(effectiveDateTab, (chainId: string | undefined) => {
      if (chainId) {
        API.getAllUsersByRole(chainId).then((res: any) => {
          allUserOptions.value = res
        })
        if (!approvalChainInfo[chainId].inited) {
          API.getApprovalChainDetail(chainId).then((res: any) => {
            const data = deepClone(approvalChainInfoDefaultData)
            res.forEach((item: any) => {
              data[item.approvalLevel - 1].approvalList = item.approvalList
              data[item.approvalLevel - 1].approvalList.forEach((item: any) => {
                item.roleOptions = [{
                  id: item.approvalChainRoleId,
                  name: item.approvalChainRoleName
                }]
                item.userOptions = [{
                  id: item.approvalChainUserId,
                  name: item.approvalChainUserName
                }]
              })
            })
            approvalChainInfo[chainId].data = data
            approvalChainInfo[chainId].inited = true
          })
        }
      }
    })

    const inEditting = computed(() => {
      return effectiveDateTab.value && approvalChainInfo[effectiveDateTab.value].editting
    })

    const columns = ref([
      {
        slots: { title: 'customTitle', customRender: 'approvalLevel' },
      },
      {
        title: 'ApprovalRole',
        dataIndex: 'approvalRole',
        width: '35%',
        slots: { customRender: 'approvalRole' },
      },
      {
        title: 'User',
        dataIndex: 'user',
        width: '35%',
        slots: { customRender: 'user' },
      },
      {
        title: computed(()=> {return inEditting.value?'Operation':''}),
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
      },
    ])
    const handleToEdit = async() => {
      if (effectiveDateTab.value) {
        const res: any = await API.editableCheckApprovalChain(effectiveDateTab.value)
        approvalChainInfo[effectiveDateTab.value].editting = res.code === "0"
        // if (res.code !== "0") {
        //   message.error(res.message)
        // }
      }
    }
    const handleAddRoleAndUser = (record: any, index: number) => {
      record.approvalList.splice(index + 1, 0, {
        approvalChainRoleName: '',
        approvalChainRoleId: undefined,
        roleOptions: [],
        approvalChainUserName: '',
        approvalChainUserId: undefined,
        userOptions: allUserOptions.value
      })
    }

    const handleRemoveRoleAndUser = (record: any, rindex: number, index: number) => {
      record.approvalList.splice(rindex, 1)
      if (record.approvalList.length === 0) {
        if (effectiveDateTab.value) {
          approvalChainInfo[effectiveDateTab.value].data.splice(index, 1)
        }
      }
    }

    const canAddLevel = computed(() => {
      return inEditting.value && effectiveDateTab.value &&
        approvalChainInfo[effectiveDateTab.value].data.length < 15
    })

    const handleAddLevel = () => {
      if (effectiveDateTab.value) {
        approvalChainInfo[effectiveDateTab.value].data.push({
          approvalList: [{
            approvalChainRoleName: '',
            approvalChainRoleId: undefined,
            roleOptions: [],
            approvalChainUserName: '',
            approvalChainUserId: undefined,
            userOptions: allUserOptions.value
          }]
        })
      }
    }

    const handleToSelectRole = async (approval: any) => {
      const res = await API.getAllRolesByUser(effectiveDateTab.value,
        approval.approvalChainUserId === undefined ? '' : approval.approvalChainUserId)
      approval.roleOptions = res
    }

    const handleToSelectUser = (approval: any) => {
      if (approval.approvalChainRoleId === undefined) {
        approval.userOptions = allUserOptions.value
      }
    }

    const handleSelectRole = async (roleId: string, approval: any) => {
      if (roleId !== undefined) {
        const res: any = await API.getAllUsersByRole(effectiveDateTab.value, roleId)
        approval.userOptions = res
        const selectedRoleOption = approval.roleOptions.find((item: any) => {
          return item.id === roleId
        })
        approval.approvalChainRoleName = selectedRoleOption.name
        if (res.length === 1) {
          approval.approvalChainUserId = res[0].id
          approval.approvalChainUserName = res[0].name
        }
      } else {
        approval.approvalChainUserId = undefined
        approval.approvalChainUserName = ''
        approval.userOptions = allUserOptions.value
      }
    }

    const handleSelectUser = async (userId: string, approval: any) => {
      if (userId !== undefined) {
        const selectedUserOption = approval.userOptions.find((item: any) => {
          return item.id === userId
        })
        approval.approvalChainUserName = selectedUserOption.name
      }
    }

    const handleSave = async () => {
      if (chainName.value.trim() === '') {
        message.error('Please enter Approval Chain!')
        return
      }
      const dataList: any[] = []
      if (effectiveDateTab.value) {
        approvalChainInfo[effectiveDateTab.value].data.forEach((levelItem: any, index: number) => {
          const approvalList: any[] = [];
          levelItem.approvalList.forEach((approvalItem: any) => {
            if (approvalItem.approvalChainRoleId && approvalItem.approvalChainUserId) {
              approvalList.push({
                approvalChainRoleName: approvalItem.approvalChainRoleName,
                approvalChainRoleId: approvalItem.approvalChainRoleId,
                approvalChainUserName: approvalItem.approvalChainUserName,
                approvalChainUserId: approvalItem.approvalChainUserId
              })
            }
          })
          if (approvalList.length) {
            dataList.push({
              approvalLevel: index + 1,
              approvalList
            })
          }
        })
      }
      const params = {
        bu: buName.value,
        businessId: route.query.businessId as string,
        chainId: effectiveDateTab.value,
        chainName: chainName.value,
        dataList,
        effectiveFrom: ''
      }
      const {code, message: msg} = await API.saveApprovalRole(params) as any
      if (code !== '0') {
        message.error(msg)
        return
      }
      if (effectiveDateTab.value) {
        approvalChainInfo[effectiveDateTab.value].editting = false
      }
    }

    const mbCenter = ref('MB Central')
    const activeKey = ref('1')
    const tabVisable = ref<boolean>(false)
    const ntimes = ref<number>(2)
    const chainVersionModalVisible = ref<boolean>(false)
    const tabGroup = reactive<TabItem[]>([])
    const addVersion = () => {
      chainVersionModalVisible.value = true
    }

    const handleAddNewVersion = async (effectiveDateStr: string) => {
      const params = {
        approvalBusinessId: route.query.businessId as string,
        bu: buName.value,
        newVersionEffectiveFrom: effectiveDateStr
      }
      const {code, data: chainId, message: msg} = await API.addApprovalChainNewVersion(params) as any
      if (code !== '0') {
        message.error(msg)
        return;
      }
      effectiveDates.value.push({
        currentEffectiveFlag: null,
        effectiveDate: effectiveDateStr,
        effectiveFormatDate: moment(effectiveDateStr).format("MM/DD/YYYY"),
        chainId
      })
      const activeEffectiveDate: EffectiveDate | undefined = effectiveDates.value.find((effectiveDate: EffectiveDate) => {
        if (effectiveDate.currentEffectiveFlag) {
          return true
        }
      })
      console.log(activeEffectiveDate)
      approvalChainInfo[chainId] = {
        data: activeEffectiveDate ? deepClone(approvalChainInfo[activeEffectiveDate.chainId].data) : [],
        inited: true,
        editting: true
      }
      effectiveDateTab.value = chainId
      message.success("Add success")
    }

    return {
      mbCenter,
      activeKey,
      tabVisable,
      ntimes,
      addVersion,
      chainVersionModalVisible,
      handleAddNewVersion,
      tabGroup,
      tabDate,
      chainName,
      buName,
      makeName,
      approvalBusinessId,
      effectiveDates,
      effectiveDateTab,
      approvalChainInfo,
      columns,
      handleToEdit,
      handleAddRoleAndUser,
      inEditting,
      handleRemoveRoleAndUser,
      canAddLevel,
      handleAddLevel,
      handleToSelectRole,
      handleSelectRole,
      handleSelectUser,
      handleSave,
      handleToSelectUser
    };
  }
});
